import React, {useEffect, useState} from "react"
import {api} from "../../api/api"

import styles from "./index.module.css"
import {Schedule} from "./Schedule";
import cn from "classnames";
import {TabList} from "./TabList";

export const Content = () => {
    const [currencyList, setCurrencyList] = useState(null)
    const [currentTabIndex, setCurrentTabIndex] = useState(0)

    useEffect(() => {
        const getCurrencyList = async () => {
            const res  = await api.getCurrencyList()

            if (Array.isArray(res.data)) {
                setCurrencyList(res.data)
            }
        }
        getCurrencyList()

    }, [])

    return <div className={cn("center-container", styles.Wrapper)}>
        <div className={styles.Inner}>
            {
                Array.isArray(currencyList) && currencyList.length > 0 && <><TabList list={currencyList} currentTabIndex={currentTabIndex} clickHandler={setCurrentTabIndex}  />
                    <Schedule pairName={currencyList[currentTabIndex].currency_pair} /></>
            }
        </div>
    </div>
}
