import React, {useEffect, useRef} from "react"
import cn from "classnames";

import styles from "./index.module.css";

const Tab = ({name, isActive, clickHandler}) => {
    return <button onClick={clickHandler} className={cn(styles.Button, {[styles.ButtonActive]: isActive})}>{name}</button>
}

export const TabList = ({list, currentTabIndex, clickHandler}) => {
    const ref = useRef()

    useEffect(() => {
        if (window.innerWidth <= 768) {
            const scrollTabs = (e) => {
                if (ref.current && e.currentTarget) {
                    e.preventDefault()
                    ref.current.scrollLeft += e.deltaY
                    ref.current.scrollLeft += e.deltaX
                }
            }

            if (ref.current) {
                ref.current.addEventListener("wheel", scrollTabs)
            }

            return () => {
                if (ref.current) {
                    ref.current.removeEventListener("wheel", scrollTabs)
                }
            }
        }

    }, [ref.current, window.innerWidth])

    return <div className={styles.TabsWrapper} ref={ref}>
        {list.map((tab, index) => <Tab key={index} name={tab.currency_pair} clickHandler={() => clickHandler(index)} isActive={index === currentTabIndex} />)}
    </div>
}
