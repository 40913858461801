import axios from "axios";

// const URL = "https://api.steam-currency.ru/test/";
const URL = "https://api.steam-currency.ru/";
/*const API_KEY = "bc8edd7a-1cd6-4f52-81e7-dff6464c7445";*/

const token = document.getElementById("root").getAttribute("data-token")

const instanceAxios = axios.create({
    withCredentials: true,
    baseURL: URL,
});

instanceAxios.interceptors.request.use((config) => {
    // Если заголовок "token" отсутствует, добавляем его, не заменяя другие заголовки
    if (!config.headers['token']) {
        config.headers['token'] = token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export const api = {
    getCurrencyList() {
        return (
            instanceAxios.get("currency")
                .then((response) => {
                    return response.data;
                })
        );
    },
    getCurrencyRate(currencyPair = "USD:RUB") {
        return (
            instanceAxios.get(`currency/${currencyPair}`)
                .then((response) => {
                    return response.data;
                })
        );
    }
}
