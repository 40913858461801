import React, {useRef, useState, useEffect} from "react"
import { createPortal } from 'react-dom'

import CloudtipsWallet from "./assets/cloudtips_wallet.png"
import USDT from "./assets/usdttrc.png"
import ETH from "./assets/eth.png"
import ETHIcon from "./assets/ethIcon.png"
import USDTIcon from "./assets/usdtIcon.png"
import CloudtipsIcon from "./assets/cloudtipsIcon.png"
import CopyIcon from "./assets/copy.png"
import styles from "./index.module.css"
import cn from "classnames";
import useOutsideClick from "../../helpers/useOutsideClick";
import Popup from "../Popup";

const URL = "https://api.steam-currency.ru/"

const methods = [
    {name: "Cloudtips", icon: CloudtipsIcon, url: CloudtipsWallet, link: "https://pay.cloudtips.ru/p/bf82b396"},
    {name: "ETH", icon: ETHIcon, url: ETH, address: "0xc6546f5Ea2baf47Ba3Bbac1900e27d25cC5fdd25", text: "Этот адрес может принимать только ETH. Чтобы предотвратить потерю актива, НЕ отправляйте на этот адрес другую валюту."},
    {name: "USDT(TRC20)", icon: USDTIcon, url: USDT, address: "TPJNYpxsZC1k9B4sPt2MCrd1V8n8TTrWs8", text: "Этот адрес может принимать только USDT(TRC20). Чтобы предотвратить потерю актива, НЕ отправляйте на этот адрес другую валюту."},
]

const Portal = ({children}) => {
    return <>{createPortal(children, document.body)}</>
}

const MethodPopup = ({method, closeHandler}) => {
    const [isShowCopyNotice, setIsShowCopyNotice] = useState(false)
    const ref = useRef()
    const copyText = () => {
        navigator.clipboard && navigator.clipboard.writeText(method.address)
        setIsShowCopyNotice(true)
    }

    useOutsideClick(ref, closeHandler)

    useEffect(() => {
        if (isShowCopyNotice) {
            const timerId = setTimeout(() => {
                setIsShowCopyNotice(false)
                clearTimeout(timerId)
            }, 2000)
        }
    }, [isShowCopyNotice])

    return <Popup title={method.name} closeHandler={closeHandler}>
       {method.text && <p className={styles.Text}>{method.text}</p>}
       <img src={method.url} alt={method.name} className={styles.QR} />
       {method.link && <p className={styles.LinkWrapper}><a href={method.link} target="_blank"  className={cn("hidden-x-scroll", styles.Address)}>{method.link}</a></p>}
       {method.address && <div className={styles.AddressWrapper}>
           <div className={cn("hidden-x-scroll", styles.Address)}><p onClick={copyText}>{method.address}</p></div>
           <button className={styles.CopyButton}  onClick={copyText}><img className={styles.CopyIcon} src={CopyIcon} alt="Копировать"/></button>
           {isShowCopyNotice && <p className={styles.CopyNotice}>Адрес скопирован</p>}
       </div>}
    </Popup>
}

const Method  = ({method}) => {
    const [isOpenPopup, setIsOpenPopup] = useState(false)

    return <>
        <button className={styles.Method} onClick={() => {
            setIsOpenPopup(true)
        }}>
            {method.icon && <img className={styles.Icon} src={method.icon} />}
            {method.name}
        </button>

        {isOpenPopup && <MethodPopup method={method} closeHandler={() => setIsOpenPopup(false)} />}
    </>
}

export const Gratitude = () => {
    return <div className={cn("center-container", styles.Wrapper)}>
        {methods.map((method) => <Method method={method} key={method.name} />)}
    </div>
}
