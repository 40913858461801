import React, {useEffect, useMemo, useRef, useState} from "react"
import {api} from "../../../api/api"
import {AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area} from "recharts"
import moment from "moment"

import styles from "./index.module.css"
import {CurrentCourse} from "../CurrentCourse";
import {dateFormatting} from "../../../helpers/dateFormatting";

export const Schedule = ({pairName}) => {
    const [pairData, setPairData] = useState(null)
    const [chartWidth, setChartWidth] = useState(0)
    const ref = useRef(null)
    const lastIndex = useMemo(() => {
        return Array.isArray(pairData) ? pairData.length - 1 : 0
    }, [pairData])

    const changeChartWidth = () => {
        if (ref.current) {
            setChartWidth(ref.current.clientWidth - (window.innerWidth > 768 ? 40 : 10))
        }
    }

    useEffect(() => {
        const getPairData = async () => {
            const res = await api.getCurrencyRate(pairName)
            
            if (Array.isArray(res.data)) {
                setPairData(res.data)
            }
        }

        getPairData()
    }, [pairName])

    useEffect(() => {
        window.addEventListener("resize", changeChartWidth)

        return () => {
            window.removeEventListener("resize", changeChartWidth)
        }
    }, [])
    
    useEffect(() => {
        if (ref.current) {
            changeChartWidth()
        }

    }, [ref.current])
    
    
    return <div className={styles.Wrapper} ref={ref}>
        {Array.isArray(pairData) && pairData.length > 0 && <>
            <CurrentCourse currency={pairData[lastIndex].currency_pair.split(":")[1]} date={pairData[lastIndex].created_at} course={pairData[lastIndex].close_price} />
            <div className={styles.ChartWrapper}>
                {chartWidth && <>
                    <p className={styles.YLable}>{pairName.split(":")[1]}</p>
                    <AreaChart width={chartWidth} height={250}
                               data={pairData.map((item) => ({date: dateFormatting(item.created_at, "DD.MM.YY"), course: item.close_price}))}
                               margin={{ top: 10, right: 20, left: -15, bottom: 0 }}>
                        <defs>
                            <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="date" tickMargin={10} />
                        <YAxis domain={([dataMin, dataMax]) => {
                            return [Math.round(dataMin) - (dataMax > 50 ? 5 : 1), Math.round(dataMax) + (dataMax > 50 ? 5 : 1)]
                        }} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Area dataKey="course" name="Курс" stroke="#8884d8" animationDuration={500} fillOpacity={1} fill="url(#color)" />
                    </AreaChart>
                </>}
            </div>
        </>}
    </div>
}
