import React from "react"

import styles from "./index.module.css"

export const Footer = () => {
    const year = new Date().getFullYear()
    return <footer className={styles.Wrapper}>
        <div className="center-container">
            <div className={styles.Row}>
                <div className={styles.Col}>
                    <p className="copyright">© {year} steam-currency.ru</p>
                </div>

                <div className={styles.Col}>

                </div>

                <div className={styles.Col}>
                    Steam-currency — это веб-сайт сообщества, который никак не связан с Valve или Steam.
                </div>
            </div>
        </div>
    </footer>
}
