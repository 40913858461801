import React, {useState} from "react"
import {api} from "../../api/api"

import styles from "./index.module.css"
import cn from "classnames";

export const Api = () => {
    return <div className="center-container">
        <div className={styles.Wrapper}>
            <h2 className={styles.Head}>Как подключиться к API?</h2>
            <p>Всего два шага для подключения к API:</p>
            <ul className={styles.List}>
                <li>
                    <h4 className={styles.ItemTitle}>Шаг 1: Получите токен.</h4>
                    <p>
                        Чтобы получить токен, обратитесь к нашему <a className={styles.Link} rel="stylesheet" href="https://t.me/api_steam_currency_bot">Telegram-боту</a>.<br/>
                        Он проведет вас через процесс получения доступа шаг за шагом.
                    </p>
                </li>
                <li>
                    <h4 className={styles.ItemTitle}>Шаг 2: Перейдите по адресу <a className={styles.Link} href="https://api.steam-currency.ru/docs">https://api.steam-currency.ru/docs</a></h4>
                    <p>
                        Изучите доступные методы API и протестируйте токен.<br/>
                        Для этого нажмите кнопку Authorize и введите ранее полученный токен.<br/>
                        Сам же API доступен по адресу: <a className={styles.Link} href="https://api.steam-currency.ru/v3">https://api.steam-currency.ru/v3</a>.
                    </p>
                </li>
            </ul>
            <h3>Что дает подключение к API?</h3>
            <ul>
                <li>- непосредственно, доступ к данным;</li>
                <li>- доступ к расширенному количеству валютных пар;</li>
                <li>- возможность получать данные за период до 90 дней;</li>
                <li>- приоритетный доступ к обновлениям курсов без задержек;</li>
                <li>- удобный просмотр информации прямо в Telegram-боте.</li>
            </ul>
        </div>
    </div>
}
