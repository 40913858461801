import React from "react"
import {Header} from "../Header";
import {Content} from "../Content";
import {Footer} from "../Footer";

import styles from "./index.module.css"

export const Layout = ({children}) => {
    return <div className={styles.Wrapper}>
        <Header />
        {children}
        <Footer/>
    </div>
}
