import React, {useState} from "react"
import {api} from "../../api/api"

import styles from "./index.module.css"
import cn from "classnames";

const IMAGE_URL = "https://api.steam-currency.ru/"

export const ExchangeRate = () => {


    return <div className="center-container">
        <div className={styles.Wrapper}>
        <h2 className={styles.Head}>Как проверить, что курс указанный на сайте соответствует курсу steam?</h2>

        <ul className={styles.List}>
            <li>
                Заходим в раздел "Об аккаунте" (Account details), далее "История покупок" (View purchase history).
                <img className={styles.Image} src={`${IMAGE_URL}balance_1.webp`} alt="Шаг 1"/>
            </li>
            <li>
                На балансе на данный момент имеется сумма в размере 219,31 руб.
                <img className={styles.Image} src={`${IMAGE_URL}balance_2.webp`} alt="Шаг 2"/>
            </li>
            <li>
                Далее пополняете счет вашего аккаунта любым удобным способом. Для примера, я пополню счет на 100р.
                После зачисления средств (Следует понимать, что все зачисления на аккаунт производятся в долларах),
                история покупок выглядит следующим образом:
                <img className={styles.Image} src={`${IMAGE_URL}balance_3.webp`} alt="Шаг 3"/>
            </li>
            <li>
                Теперь баланс моего аккаунта составляет 320,62р. Вычтем из него значение, которое было до пополнения:
                320,62 - 219,31 = 101,31. Поскольку зачисления производятся в долларах, мы также видим в истории,
                что пополнение было на сумму $1.09 USD. Получается, что $1.09 USD = 101,31.
                Отсюда следует, что 1$ = 101,31 / 1.09 = 92.94.
            </li>
        </ul>
        </div>






    </div>
}
