import React, {useState} from "react"
import {api} from "../../api/api"

import styles from "./index.module.css"
import cn from "classnames";

const Item = ({question, answer}) => {
    const [isOpen, setIsOpen] = useState(false)
    return (<section className={cn(styles.ItemWrapper, {[styles.ItemWrapperActive]: isOpen})}>
        <h3 className={styles.Question}><button onClick={() => setIsOpen(!isOpen)} className={cn(styles.QuestionButton, {[styles.QuestionButtonActive]: isOpen})}>{question}</button></h3>
        {isOpen && <div className={styles.Answer} onClick={() =>  setIsOpen(false)}>{answer}</div>}
    </section>)
}

const faqList = [
    {question: "Что это за сайт?", answer: "Наш проект представляет из себя агрегатор внутренних курсов валют на платформе Steam.",},
    {question: "В списке нет нужной мне валютной пары, можете ли вы ее добавить?",
        answer: <>
            <p>На данный момент предоставлены наиболее востребованные в сообществе пары валют. </p>
            <p>Если вы не нашли нужную именно для вас - напишите нам на почту, чтобы мы могли узнать на какие котировки есть спрос и мы постараемся их добавить.</p>
        </>,},
    {question: "Как часто обновляется курс?", answer: "По нашим наблюдениям внутренние курсы Steam обновляются раз в сутки в 03:00 по Москве, каждый день, кроме воскресенья и понедельника.",},
    {question: "Насколько сильно отличается внутренний курс Steam от официального курса?", answer: "Поддержка Steam по этому поводу говорит, что берет информацию из \"официального банка страны\". В целом, динамика сохраняется. Однако, если сравнить в лоб курсы ЦБ России и Steam, разница все-же есть.",},
    {question: "За какой период отображаются данные на графике?", answer: "Максимальный период на данный момент - месяц.",},
    {question: "Как с вами связаться?", answer: <>По всем вопросам пишите нам на почту <a href="mailto:steam-currency@yandex.ru">steam-currency@yandex.ru</a></>,},
]

export const FAQ = () => {


    return <div className={cn("center-container", styles.Wrapper)}>
        {faqList.map((item) => <Item question={item.question} answer={item.answer} />)}
    </div>
}
