import { useEffect } from "react"

const useOutsideClick = (ref, callback) => {
    const handleClickOutside = (e) => {
        if (ref.current) {
            if (!ref.current.contains(e.target)) {
                callback(e)
            }
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [callback])
}

export default useOutsideClick
