import moment from "moment-timezone"

export const dateFormatting = (
    date,
    format = "DD/MM/YY, HH:mm",
) => {
    if (!date) return undefined

    const tz = moment.tz.guess()

    moment.tz.setDefault("UTC")
    const m = moment(date).format()

    moment.tz.setDefault(tz)

    return moment(m).format(format)
}
