import React from "react"

import styles from "./index.module.css"
import {dateFormatting} from "../../../helpers/dateFormatting"

export const CurrentCourse = ({currency, course, date}) => {
    return <div className={styles.Wrapper}>
        <p className={styles.Course}>{course} {currency}</p>
        <div className={styles.Date}>{dateFormatting(date, "DD.MM.YY HH:mm")}</div>
    </div>
}
