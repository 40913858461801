import React, {useEffect, useLayoutEffect, useState} from "react"

import styles from "./index.module.css"
import cn from "classnames"
import {NavLink, useLocation} from "react-router-dom"

const MOBILE_WITH = 800

const ApiLink = () => {
    return <NavLink to="/api"
        /* className={(navData) => (navData.isActive ? styles.ActiveLink : styles.Link)}  */
                    className={styles.Link}>
        Подключиться к API
    </NavLink>
}

const MenuList = () => {
    return <>
        <ApiLink />
        <NavLink to="/exchangeRate"
            /* className={(navData) => (navData.isActive ? styles.ActiveLink : styles.Link)}  */
                 className={styles.Link}>
            Как проверить курс
        </NavLink>
        <NavLink to="/gratitude"
            /* className={(navData) => (navData.isActive ? styles.ActiveLink : styles.Link)}  */
                 className={styles.Link}>
            Поддержать проект
        </NavLink>
        <NavLink to="/faq"
            /* className={(navData) => (navData.isActive ? styles.ActiveLink : styles.Link)}  */
                 className={styles.Link}>
            FAQ
        </NavLink>
    </>
}

const MobileMenu = () => {
    return  <div className={styles.MobileMenu}>
        <div><MenuList /></div>
    </div>
}

export const Header = () => {
    const [isMobile, setIsMobile] = useState(false)
    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)
    const location = useLocation()

    useLayoutEffect(() => {
        const changeWindowHandler = () => {
            setIsMobile(window.innerWidth <= MOBILE_WITH)
        }

        changeWindowHandler()

        window.addEventListener("resize", changeWindowHandler)
        return () => {
            window.removeEventListener("resize", changeWindowHandler)
        }
    }, [])

    useEffect(() => {
        setIsOpenMobileMenu(false)
    }, [location])

    return <header className={styles.Wrapper}>
        <div className={cn("center-container", styles.Inner)}>
            <h2 className={styles.Logo}><NavLink to="/" className={styles.Link}>Steam currency</NavLink></h2>
            <div className={styles.RightBlock}>
            {isMobile ? <>
                    {!isOpenMobileMenu && <ApiLink/>}
                    <button className={cn(styles.MobileMenuButton, {[styles.MobileMenuCloseButton]: isOpenMobileMenu, [styles.MobileMenuOpenButton]: !isOpenMobileMenu})}
                            onClick={(e) => {
                                setIsOpenMobileMenu(!isOpenMobileMenu)
                                e.currentTarget.blur()
                            }}
                    />
                    {isOpenMobileMenu && <MobileMenu />}
                </>
                    : <MenuList />
                }
            </div>
        </div>
    </header>
}
