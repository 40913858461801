import React, {useEffect, useRef, useState} from "react"
import { createPortal } from 'react-dom'
import useOutsideClick from "../../helpers/useOutsideClick";
import styles from "./index.module.css";

const Portal = ({children}) => {
    return <>{createPortal(children, document.body)}</>
}

const Popup = ({children, title, closeHandler}) => {
    const ref = useRef()

    const escapeKeyListener = (e) => {
        if (e.key === "Escape") {
            closeHandlerWithEsc()
        }
    }

    const closeHandlerWithEsc = () => {
        closeHandler()
        document.removeEventListener("keydown", escapeKeyListener)
    }

    useOutsideClick(ref, closeHandlerWithEsc)

    useEffect(() => {
        document.addEventListener("keydown", escapeKeyListener)
    }, [])

    return <Portal>
        <div className={styles.PopupWrapper}>
            <div className={styles.PopupInner} ref={ref}>
                <button title="Закрыть" className={styles.ClosePopupButton} onClick={closeHandlerWithEsc} />
                {title && <h3 className={styles.Title}>{title}</h3>}
                {children}
            </div>
        </div>
    </Portal>
}

export default Popup
