import './App.css';
import {Layout} from "./Components/Layout";
import {BrowserRouter, Route, Routes} from "react-router-dom"
import React from "react";
import {Content} from "./Components/Content";
import {FAQ} from "./Components/FAQ";
import {ExchangeRate} from "./Components/ExchangeRate";
import {Api} from "./Components/Api";
import {Gratitude} from "./Components/Gratitude";

function App() {
  return (
    <BrowserRouter>
        <div className="App">
            <Layout>
                <Routes>
                    <Route path="/api" element={<Api/>} />
                    <Route path="/exchangeRate" element={<ExchangeRate/>} />
                    <Route path="/faq" element={<FAQ/>} />
                    <Route path="/gratitude" element={<Gratitude/>} />
                    <Route path="/" element={<Content/>} />
                </Routes>
            </Layout>
        </div>
    </BrowserRouter>
  );
}

export default App
